import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, List, message, Select, Tabs } from 'antd';
import 'rc-color-picker/assets/index.css';
import React, { useEffect, useState } from 'react';
import apiRequest from '../../utils/apiRequest';
import Tarjeta from "components/Card/Tarjeta";
import FlatCard from "components/FlatCard/FlatCard";
import Transfer from "components/Transfer";
import Entrada from "components/Entrada";
import Void from "atomicComponents/Void";
import SimpleModal from "atomicComponents/SimpleModal";
import BuscadorGenerico from "components/BuscadorGenerico";

const { TabPane } = Tabs;

// TODO : FALTA DESHABILITAR EL BOTON DE GUARDAR
// TODO : FALTA ACTUALIZAR A LOS TOPS

const Bloques = () => {

    const [tops, setTops] = useState([]);
    const [campanas, setCampanas] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bloques, setBloques] = useState(null);
    const [bloqueSeleccionado, setBloqueSeleccionado] = useState(null);
    const [crearBloque, setCrearBloque] = useState({
        nombre: "",
        caracteristicas: [],
        campanas: []
    })
    const [campanasFiltradas, setCampanasFiltradas] = useState(null);
    const [caracteristicas, setCaracteristicas] = useState([])
    const [caracteristicasTop, setCaracteristicasTop] = useState([])
    const [arrayCaracteristicas, SetArrayCaracteristicas] = useState([])
    const [actualizarBloques, setActualizarBloques] = useState(false)
    // const [duplicadorBloques, setDuplicadorBloques] = useState(null);

    const [filtros, setFiltros] = useState({});
    const [selectedKeys, setSelectedKeys] = useState(null);
    const [filtroBloques, setFiltroBloques] = useState("");
    const [crearVisible, setCrearVisible] = useState(false)

    const [guardar, setGuardar] = useState(false);

    // const obj_bloqueSeleccionado = bloqueSeleccionado ? caracteristicas.filter( _car => _car._id === bloqueSeleccionado)[0] : null;
    const obj_bloqueSeleccionado = bloques ? bloques.find( _bloq => _bloq._id === bloqueSeleccionado ) : null;


    //Seleccionamos el grupo de usuarios del localStorage
    useEffect(() => {
        
        const getUser = JSON.parse(localStorage.getItem("global"))
        const userGroups = getUser?.user?.user_groups || []; //esto nos devuelve un array de numeros ej:[4,7,8]

        //recorremos el array para ver si hay alguna coincidencia
        const isValueInUserGroups = [4, 6, 7, 8].some(value => userGroups.includes(value));

        //si la hay ponemos el Boton de crear a true
        if (isValueInUserGroups) {
            setCrearVisible(true)
        }

    }, [])
    

    // PILLO TOPS, CAMPAÑAS Y CARACTERISTICAS
    useEffect(() => {
        (async () => {
            
            try {
                
                const getCampanas = await apiRequest("get", "/campana", null, true);
                const getTops = await apiRequest("get", "/top/all", null, true);
                const getCaracteristicas = await apiRequest("get", '/caracteristica_campana', null, true)
                const getCaracteristicasTop = await apiRequest("get", '/caracteristica_top', null, true)
                setCampanas(getCampanas);
                setCampanasFiltradas(getCampanas.slice().sort((a, b) => a.nombre.localeCompare(b.nombre)));
                setTops(getTops);
                setCaracteristicas(getCaracteristicas)
                setCaracteristicasTop(getCaracteristicasTop)
             

            } catch (err) {
                return message.error("Error al obtener los datos");
            }

        })();
    }, []);

    useEffect(() => {
        (async () => {

            try {
                const getBloques = await apiRequest("get", `/bloques`, null, true);
                setBloques(getBloques);
                setBloqueSeleccionado(getBloques.length ? getBloques[0]._id : null);

            } catch (err) {
                return message.error("Error al obtener los datos");
            }

        })();
    }, [setActualizarBloques, actualizarBloques]);



    useEffect(() => {

        if ( !arrayCaracteristicas.length ) return setCampanasFiltradas(campanas);
        else return setCampanasFiltradas(campanas.filter( _cam => {
            for (const _car of arrayCaracteristicas) {
                if (_cam.caracteristicas.some(_x => _x === _car)) return true;
            }  
            return false;
        }));

    }, [arrayCaracteristicas]);
 
    
    // CONFIGURO LAS KEYS POR DEFECTO QUE VA A TENER EL TRANSFER
    useEffect(() => {
        
        if ( !bloques ) return;

        const obj_final = {};
        bloques.forEach(_bloque => {
            
            obj_final[_bloque.nombre] = { _id : _bloque._id, campanas : _bloque.campanas.map( _cam => _cam._id ), caracteristicas : _bloque.caracteristicas };
        });
    
        setSelectedKeys( obj_final );

    }, [bloques]);
    

    const contenedores = [
        {
            key: 0,
            nombre: 'AM',
            filtro: "AM"
        },
        {
            key: 1,
            nombre: 'PM',
            filtro: "PM"
        },
        {
            key: 2,
            nombre: 'CATAS',
            filtro: "CATAS"
        },
        {
            key: 3,
            nombre: 'TODO',
            filtro: ""
        }
    ];

    // const onChange = (_e) => {
       
    //     SetArrayCaracteristicas([...arrayCaracteristicas, _e]);
    // }



    
 
    const loading = !bloques || !tops || !campanas;
    if ( loading ) return  <div className="w100 jcc fs5 pt5"> <LoadingOutlined /> </div>

    return <div className="flex jcc">

        <div className="w80 minw70em">

            <span className="fdc m3">
                <p className="fs6 bold tac colVerCor"> Editor bloques </p>
            </span>

            

            <div className="flex maxh4em">
                <span className="fs5 bold mr4"> BLOQUES CAMPAÑAS </span>
                {
                    contenedores.map((_obj, indx) => {
                        return <Button
                            type={_obj.filtro === filtroBloques ? "primary" : "secundary"}
                            onClick={() => setFiltroBloques(_obj.filtro)}
                            key={indx}
                            children={_obj.nombre}
                        />
                    })
                }
            </div>

            <Void space={.5} />
            <Void space={1} divider={true} />

            <div
                className="fww"
            >
                {bloques.filter(_bloq => _bloq.nombre.includes(filtroBloques)).map((_bloque, indx) => {
                    return <Tarjeta
                        key={indx}
                        className="w46 m2"
                        titulo={<div className="flex">
                            <EditOutlined
                                className="fs3 mr3 aic mb1"
                                onClick={(event) => {
                                    setBloqueSeleccionado(_bloque._id)
                                    event.stopPropagation();
                                }}
                            />
                            <p className="fs2"> {_bloque.nombre} </p>
                        </div>}
                        children={
                            <div className="p4 maxh20em">
                                <Tabs defaultActiveKey="1" centered>
                                    <TabPane tab="TOPS" key={`TOP_${indx}`}>
                                        <Entrada
                                            type="text"
                                            value={filtros[`${_bloque.nombre}`]?.searchTop}
                                            setValue={(search) => setFiltros({ ...filtros, [`${_bloque.nombre}`]: { ...filtros[`${_bloque.nombre}`], searchTop: search } })}
                                        />
                                        <List
                                            className="demo-loadmore-list h10em ofys"
                                            itemLayout="horizontal"
                                            dataSource={
                                                tops.filter((_top) => {
                                                    let valido = _bloque.caracteristicas.length ? true : false;
                                                    _bloque.caracteristicas.forEach((_car) => {
                                                        if (!_top.caracteristicas || !_top.caracteristicas.map(_x => _x._id).includes(_car)) valido = false;
                                                    })
                                                    return valido;
                                                }).filter(_top => {
                                                    const busqueda = filtros[`${_bloque.nombre}`]?.searchTop ? filtros[`${_bloque.nombre}`]?.searchTop.toLowerCase() : "";
                                                    return _top.nombre.toLowerCase().includes(busqueda);
                                                }).slice().sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente por 'nombre'
                                            }
                                            renderItem={(item) => <p className="m2"> {item.nombre} </p>}
                                        /> 
                                    </TabPane>
                                    <TabPane tab={`Campañas (${_bloque.campanas.filter(_cam => {
                                        const busqueda = filtros[`${_bloque.nombre}`]?.searchCam ? filtros[`${_bloque.nombre}`]?.searchCam.toLowerCase() : "";
                                        return _cam.nombre.toLowerCase().includes(busqueda);
                                    }).slice().length})`} key={`CAM_${indx}`}>
                                       
                                        <Entrada
                                            type="text"
                                            value={filtros[`${_bloque.nombre}`]?.searchCam}
                                            setValue={(search) => setFiltros({ ...filtros, [`${_bloque.nombre}`]: { ...filtros[`${_bloque.nombre}`], searchCam: search } })}
                                        />
                                        
                                      
                                        <List
                                            className="demo-loadmore-list h10em ofys"
                                            itemLayout="horizontal"
                                            dataSource={
                                                _bloque.campanas.filter(_cam => {
                                                    const busqueda = filtros[`${_bloque.nombre}`]?.searchCam ? filtros[`${_bloque.nombre}`]?.searchCam.toLowerCase() : "";
                                                    return _cam.nombre.toLowerCase().includes(busqueda);
                                                }).slice().sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente por 'nombre'
                                            }
                                            renderItem={(item) => <p className="m2"> {item.nombre} </p>}
                                        />
                                        {
                                            (_bloque.nombre.endsWith("AM") || _bloque.nombre.endsWith("PM")) && <Button
                                                className="mt3 mb3"
                                                style={{ float: 'right' }}
                                                type="primary"
                                                onClick={async () => {
                                                  
                                                    const { ok } = await apiRequest("put", "/clone_bloques", {
                                                        campanas: _bloque.campanas,
                                                        bloque: _bloque.nombre.endsWith("AM") ? bloques.find(__bloque => __bloque.nombre === _bloque.nombre.replace(" AM", " PM")) : bloques.find(__bloque => __bloque.nombre === _bloque.nombre.replace(" PM", " AM"))
                                                    }, true);

                                                    if (!ok) return message.error("No se han clonado las campañas");
                                                    message.success("Campañas clonadas correctamente");
                                                    setActualizarBloques(!actualizarBloques)
                                                }}

                                            >CLONAR CAMPAÑAS A {`${_bloque.nombre.endsWith("AM") ? _bloque.nombre.replace(" AM", " PM") : _bloque.nombre.replace(" PM", " AM") }`}</Button>
                                        }
                                    </TabPane>
                                </Tabs>
                            </div>
                        }
                        collapse
                        collapseDefaultOpen
                    />
                })
                }
            </div>
            {
                crearVisible && 
                <div className="mt4">
                    <Button
                        type="primary"
                        onClick={() => setIsModalOpen(true)}
                    >CREAR NUEVO BLOQUE</Button>
                    <SimpleModal
                        showModal={isModalOpen}
                        setShowModal={setIsModalOpen}
                        title="Crear bloque"
                        body={
                            <div className="flex fdc">
                                <p>Introduzca el nombre del Bloque</p>
                                <Entrada
                                    type="text"
                                    label="Nombre"
                                    value={crearBloque.nombre}
                                    setValue={(e) =>
                                        setCrearBloque({
                                            ...crearBloque,
                                            nombre: e
                                        })
                                    }
                                />

                                <p>Caracteristicas</p>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Caracteristicas"
                                    onChange={(res) => { setCrearBloque({ ...crearBloque, caracteristicas: res }) }}
                                    options={caracteristicasTop.map(_x => ({ label: _x.nombre , value: _x._id}))}
                                />
                            </div>
                        }
                        onOk={async () => {

                            const valido = (() => {

                                if ( !crearBloque.nombre ) return false;
                                if ( crearBloque.caracteristicas.length !== 2 ) return false;

                                // No pueden existir dos bloques con las mismas caracteristicas
                                let duplicado = null;
                                bloques.forEach( _x => {
                                    
                                    if ( duplicado ) return;

                                    if (_x.caracteristicas.length === crearBloque.caracteristicas.length ) {
                                        let existe = true;
                                        _x.caracteristicas.forEach( _car => { if ( !crearBloque.caracteristicas.includes( _car ) ) existe = false; });
                                        if ( existe ) duplicado = _x.nombre;
                                    }

                                });

                                if ( duplicado ) return false;

                                return true;

                            })();

                            if ( !valido ) return message.error( "El bloque debe tener nombre y dos caracteristicas 'AM' o 'PM' y una más" );
                            message.success("Bloque creado correctamente!");

                            const nuevoBloque = await apiRequest("post", "/bloques", { bloque: crearBloque }, true);
                            setBloques([...bloques, nuevoBloque.ops[0]]);
                            setIsModalOpen(false);
                        }}
                    />
                </div>
            }
           
            {
                bloqueSeleccionado && <div>
                    <Void space={3} divider/>

                    <div className="flex jcc fs6">
                        BLOQUE ACTUAL: <span className="ml3 bold"> { obj_bloqueSeleccionado.nombre } </span>
                    </div>

                    <Void space={3} divider/>
                </div>
            }

            {bloqueSeleccionado && <FlatCard
                initialCollapsed={ false }
                collapsable
                title={<p className="fs5 bold colVerCor"> CAMPAÑAS</p>}
            >
               
          

                 <BuscadorGenerico
                        placeholder="Selecciona una Caracteristica"
                        listado={caracteristicas}
                        onChange={val => {
                            SetArrayCaracteristicas(val)
                        }}
                        value={arrayCaracteristicas}
                        mode={"multiple"}
                        className="mb4"
                    /> 

                <Transfer
                    dataSource={campanasFiltradas}
                    oneWay={false}
                    valueKey="_id"
                    searchFnc= {(inputValue, option) => {
                            const regex = new RegExp(`.*${inputValue}.*`, "i");
                                if (regex.test(option.nombre)) return true;
	                    }}
                    render={item => item.nombre}
                    rowKey={item => item._id}
                    value={selectedKeys[obj_bloqueSeleccionado.nombre]?.campanas}
                    setValue={(transfered, direction, moveKeys) => {

                        let finalArray = [ ...selectedKeys[obj_bloqueSeleccionado.nombre]?.campanas ];

                        if ( direction === "right" ) finalArray = [...finalArray, ...moveKeys];
                        else if ( direction === "left" ) finalArray = finalArray.filter( _x => !moveKeys.includes( _x ) );

                        setSelectedKeys({
                            ...selectedKeys,
                            [obj_bloqueSeleccionado.nombre]: {
                                _id: obj_bloqueSeleccionado._id,
                                campanas: finalArray,
                                caracteristicas: obj_bloqueSeleccionado.caracteristicas
                            }
                        })
                    }}
                />

            </FlatCard>
            }

            <Button
                size="large"
                type="primary"
                className="w100 mt5 mb2"
                disabled={!obj_bloqueSeleccionado || guardar}
                onClick={async () => {

                    message.info("Guardando los datos...");
                    setGuardar(true);

                    setBloqueSeleccionado(null);

                    // "nuevosBloques" ES PARA EDITAR LOS BLOQUES EN LOCAL
                    const nuevosBloques = [...bloques];
                    const bulkBloques = Object.keys(selectedKeys).map(_key => {

                        const obj = selectedKeys[_key]
                        const nuevas_campanas = campanas
                            .filter(_cam => obj.campanas.includes(_cam._id))
                            .map(_cam => { return { _id: _cam._id, nombre: _cam.nombre, descripcion: _cam.descripcion } })

                        nuevosBloques.find(_bloque => _bloque._id === obj._id).campanas = nuevas_campanas;

                        return {
                            updateOne: {
                                filter: { _id: obj._id },
                                update: {
                                    $set: {
                                        campanas: nuevas_campanas
                                    }
                                }
                            }
                        }

                    })
                    setBloques(nuevosBloques);

                    // "nuevosTops" ES PARA EDITAR LOS TOPS EN LOCAL * ¡¡¡ NO HACE FALTA CAMBIARLOS EN LOCAL !!!
                    const bulkTops = Object.keys(selectedKeys).map(_key => {

                        const obj = selectedKeys[_key]

                        const filtroTops = tops
                            .filter((_top) => {
                                let valido = obj.caracteristicas.length ? true : false;
                                obj.caracteristicas.forEach((_car) => {
                                    if (!_top.caracteristicas || !_top.caracteristicas.map(_x => _x._id).includes(_car)) valido = false;
                                })
                                return valido;
                            })
                            .map(_top => { return { _id: _top._id } });

                        const nuevasCampanas = campanas
                            .filter(_cam => obj.campanas.includes(_cam._id))
                            .map(_cam => { return { _id: _cam._id, nombre: _cam.nombre, descripcion: _cam.descripcion } })

                        if (filtroTops.length) return {
                            updateMany: {
                                filter: {
                                    $or: filtroTops
                                },
                                update: {
                                    $set: {
                                        campanas: nuevasCampanas
                                    }
                                }
                            }
                        }

                        return null;


                    })
                    const _bulkTops = bulkTops.filter(_bulk => _bulk);

                    const actualizarBloques = await apiRequest("put", "/bloques", { bulk: bulkBloques }, true);
                    const actualizarTops = await apiRequest("put", "/campanasTops", { bulk: _bulkTops }, true);

                    setGuardar(false);

                    message.success("Cambios guardados!");

                }}
            >
                Guardar
            </Button>
        </div>

    </div>

};

export default Bloques;